// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IChart,
  IChartValues
} from "@apm/widgets/build/widgets/ParameterCharts";
import { isEmpty } from "lodash";
import { IntlShape } from "react-intl";
import gasSettings from "../constants/gasSettings";
import IGas from "../models/IGas";
import { GasName } from "../models/IGasSettings";
import IReadings from "../models/IReadings";
import IThresholds from "../models/IThresholds";
import getGasDisplayName from "../utils/getGasDisplayName";
import mapToMinMaxRegions from "./mapToMinMaxRegions";
import mapToRegions from "./mapToRegions";
import mapToThresholds from "./mapToThresholds";

const mapToChart = (
  intl: IntlShape,
  gas: IGas | undefined,
  gasStandard: string,
  gasStandardRevision: string,
  gasName: string
): IChart => {
  const settings = gasSettings[gasName as GasName];

  const getRegions = (
    thresholds: IThresholds,
    reversed: boolean,
    readings: IReadings
  ) => {
    if (settings?.useMinMaxRegions) return mapToMinMaxRegions(thresholds);
    if (settings?.useRegions && !isEmpty(readings))
      return mapToRegions(thresholds, reversed);
  };

  const getThresholds = (thresholds: IThresholds) =>
    settings?.useMinMaxRegions || settings?.useRegions
      ? undefined
      : mapToThresholds(
          intl,
          gasName,
          gasStandard,
          gasStandardRevision,
          thresholds
        );

  const name = settings?.name ?? gasName;
  const chart: IChart = {
    name: gasName,
    displayName: getGasDisplayName(intl, name, name, settings?.formula),
    displayNameReversed: settings?.reversedFormula
      ? getGasDisplayName(
          intl,
          name,
          settings?.reversedName,
          settings?.reversedFormula
        )
      : undefined,
    adjustYSizeToPositiveValues: true
  };

  if (!gas) return chart;

  const readingKeys = Object.keys(gas.Readings);

  const gasValues: {
    readingKey: string;
    defaultReadingKey: string;
    values: { [key: string]: IChartValues };
  }[] = [];

  const defaultReadingKey = gas.DefaultReadingKey;
  for (const readingKey of readingKeys) {
    const readings = gas.Readings[readingKey];
    const reversed = readingKey !== defaultReadingKey;
    gasValues.push({
      readingKey,
      defaultReadingKey,
      values: {
        default: {
          thresholds: getThresholds(readings.OnlineThresholds),
          regions: getRegions(
            readings.OnlineThresholds,
            reversed,
            readings.Offline
          ),
          reversedThresholds: true,
          mainSeriesName: "online_data",
          series: [
            {
              name: "online_data",
              unit: settings?.unit,
              values: readings.Online
            },
            {
              name: "offline_data",
              unit: settings?.unit,
              color: "black",
              values: readings.Offline
            }
          ]
        },
        offline: {
          thresholds: getThresholds(readings.OfflineThresholds),
          regions: getRegions(
            readings.OfflineThresholds,
            reversed,
            readings.Offline
          ),
          reversedThresholds: true,
          series: [
            {
              name: "offline_data",
              unit: settings?.unit,
              color: "black",
              values: readings.Offline
            }
          ]
        },
        advanced: {
          thresholds: getThresholds(readings.AdvancedOnlineThresholds),
          regions: getRegions(
            readings.AdvancedOnlineThresholds,
            reversed,
            readings.Offline
          ),
          reversedThresholds: true,
          mainSeriesName: "online_data",
          series: [
            {
              name: "online_data",
              unit: settings?.unit,
              values: readings.AdvancedOnline
            },
            {
              name: "offline_data",
              unit: settings?.unit,
              values: readings.Offline
            }
          ]
        }
      }
    });
  }

  const defaultGasValues = gasValues.filter(
    (x) => x.readingKey === x.defaultReadingKey
  )[0];
  const reversedGasValues = gasValues.filter(
    (x) => x.readingKey !== x.defaultReadingKey
  )[0];

  chart.values = defaultGasValues?.values;
  chart.valuesReversed = reversedGasValues?.values;

  return chart;
};

export default mapToChart;
